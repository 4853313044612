import "./App.css";
import ParticlesComponent from "./components/particles";

function App() {
	return (
		<div className="App">
			<ParticlesComponent id="particles" />
			<div className="prevent-select">
				<h1>Romain Morvant</h1>
				<p>
					{" "}
					<em>Site actuellement en construction</em>{" "}
				</p>
			</div>
		</div>
	);
}

export default App;
